/* NewspaperFeels.css */
.grid-container-newspaper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.grid-newspaper {
  display: grid;
  gap: 1rem;
  padding: 0.5rem;
  grid-template-columns: repeat(4, 2fr);
  grid-auto-rows: minmax(100px, auto);
  -ms-grid-row-align: center;
  break-inside: avoid;
  grid-auto-flow: dense;
}

@media (max-width: 1200px) {
  .grid-newspaper {
    grid-template-columns: repeat(3, 2fr);
  }
}

@media (max-width: 900px) {
  .grid-newspaper {
    grid-template-columns: repeat(2, 2fr);
  }
}

@media (max-width: 600px) {
  .grid-newspaper {
    grid-template-columns: 1fr;
  }
}
