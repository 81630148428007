@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply bg-background font-bold antialiased;
  }
}

.title {
  @apply text-black dark:text-parewacreamcanprimary-200 font-light text-2xl capitalize text-center my-8;
}

.news-card-title {
  @apply dark:text-[#f3f0f0] text-zinc-900 sm:text-lg 2xl:text-xl font-semibold subpixel-antialiased;
}

.news-card-withimg-title {
  @apply text-white sm:text-lg 2xl:text-xl font-semibold subpixel-antialiased;
}

.news-card-description {
  @apply text-gray-600 dark:text-[#ababab] text-sm tracking-wide w-full font-medium subpixel-antialiased;
}

.news-card-withimg-description {
  @apply text-zinc-300 text-sm tracking-wide w-full font-medium subpixel-antialiased;
}

.news-card-source {
  @apply font-semibold hover:underline hover:text-zinc-800 dark:text-parewacreamcanprimary-200 dark:hover:text-opacity-50;
}

.news-card-withimg-source {
  @apply font-semibold hover:underline hover:text-parewacreamcanprimary-200 dark:text-parewacreamcanprimary-200 dark:hover:text-opacity-50;
}

.news-card-source-hero-v2 {
  @apply font-semibold dark:text-parewacreamcanprimary-200;
}

.news-card-category {
  @apply text-zinc-500 dark:text-[#d8b858];
}

.news-card-category-body {
  @apply inline-block font-Comfortaa uppercase py-1 px-2 rounded bg-parewacreamcanprimary-100 text-black font-semibold text-xs tracking-widest;
}

.news-card-category-body-hero-v2 {
  @apply inline-block font-Comfortaa uppercase py-1 px-2 rounded bg-parewacreamcanprimary-100 text-black font-semibold text-xs tracking-widest;
}

.news-card-learn-more-button {
  @apply text-black dark:text-[#d8b858] font-Comfortaa text-sm bg-zinc-200 dark:bg-zinc-700 inline-flex items-center duration-300 hover:bg-opacity-30 dark:hover:bg-opacity-50 transition-all dark:hover:bg-zinc-700 p-2 rounded-sm;
}

.sorting-category-label {
  @apply tracking-wider bg-zinc-200 bg-opacity-50 rounded-lg dark:bg-gradient-to-br dark:from-zinc-800 dark:to-zinc-900 xxs:text-xs xl:text-sm xxs:w-fit lg:w-40 xxs:mt-2 lg:mt-2 p-2 xxs:ml-0 lg:ml-1 dark:text-parewacreamcanprimary-200 text-zinc-500;
}

.sorting-category-container {
  @apply flex xxs:flex-col lg:flex-row justify-start xxs:items-start lg:items-center xxs:gap-0 lg:gap-2 w-full;
}

.sorting-category-buttons {
  @apply flex justify-start items-center max-w-full gap-2 xxs:mt-0 lg:mt-4 dark:text-zinc-300 overflow-x-scroll no-scrollbar py-2;
}

/* @layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
} */

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    width: 6px;
  }

  .no-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
  }

  .no-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #7a5a19;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: thin;

    scrollbar-color: #8b8b8b transparent;
  }
}

/* Updated Swiper styles for better responsiveness */
.swiper {
  width: 100%;
  height: 100%;
  padding: 15px 0;
  /* Remove inline padding and handle it in the container */
  position: relative;
}

/* Container with proper padding for the swiper */
.swiper-container {
  width: 100%;
  padding: 0 30px; /* Add padding for navigation buttons */
  position: relative;
  overflow: hidden;
}

@media (min-width: 640px) {
  .swiper-container {
    padding: 0 40px;
  }
}

.responsive-swiper {
  width: 100%;
  overflow: hidden;
  position: static; /* Important for button positioning */
}

.responsive-swiper .swiper-slide {
  width: auto;
  height: auto;
}

/* Navigation buttons - positioned absolutely relative to container */
.responsive-swiper .swiper-button-prev,
.responsive-swiper .swiper-button-next {
  @apply bg-parewacreamcanprimary-100 rounded-full shadow-md;
  width: 30px !important;
  height: 30px !important;
  margin-top: -15px;
  position: absolute;
  top: 50%;
  z-index: 40;
}

.responsive-swiper .swiper-button-prev {
  left: 5px !important;
}

.responsive-swiper .swiper-button-next {
  right: 5px !important;
}

.responsive-swiper .swiper-button-prev:after,
.responsive-swiper .swiper-button-next:after {
  font-size: 14px !important;
  font-weight: bold;
}

@media (min-width: 640px) {
  .responsive-swiper .swiper-button-prev,
  .responsive-swiper .swiper-button-next {
    width: 40px !important;
    height: 40px !important;
    margin-top: -20px;
  }

  .responsive-swiper .swiper-button-prev {
    left: 10px !important;
  }

  .responsive-swiper .swiper-button-next {
    right: 10px !important;
  }

  .responsive-swiper .swiper-button-prev:after,
  .responsive-swiper .swiper-button-next:after {
    font-size: 16px !important;
  }
}
