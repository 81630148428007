.markdown {
  font-family: "Comfortaa", sans-serif;
  line-height: 1.6;
  pointer-events: none;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.markdown h1 {
  font-size: 2rem;
}

.markdown h2 {
  font-size: 1.75rem;
}

.markdown h3 {
  font-size: 1.5rem;
}

.markdown ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.markdown ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
}

.markdown li {
  margin-bottom: 0.5rem;
}

.markdown p {
  margin-bottom: 1rem;
}

.markdown a {
  color: #0366d6;
  text-decoration: none;
}

.markdown a:hover {
  text-decoration: underline;
}
